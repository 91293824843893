import React, { useState } from "react";
import "./Sidebar.css";
import Logo from "../../assets/logo.svg";
import LightLogo from "../../assets/light-logo.svg";

import {
  RiHome2Line,
  RiUser3Line,
  // RiBriefcase2Line,
  RiStackLine,
  // RiDraftLine,
  RiChat3Line,
  RiFileList3Line,
  RiMoonLine,
  RiSunLine,
  RiMenu2Line,
} from "react-icons/ri";

const Sidebar = (props) => {
  const [toggle, showMenu] = useState(false);

  return (
    <>
      <aside className={toggle ? "aside show-menu pb-[5.5rem]" : "aside"}>
        <a href="#home" className="nav__logo">
          <img src={props.theme === "light" ? LightLogo : Logo} alt="logo" />
        </a>

        <nav className="nav ">
          <div className="nav__menu">
            <ul className="nav__list">
              <li className="nav__item">
                <a href="#home" className="nav__link">
                  <RiHome2Line />
                </a>
              </li>

              <li className="nav__item">
                <a href="#about" className="nav__link">
                  <RiUser3Line />
                </a>
              </li>

              <li className="nav__item">
                <a href="#services" className="nav__link">
                  <RiFileList3Line />
                </a>
              </li>

              {/* <li className="nav__item">
                <a href="#resume" className="nav__link">
                  <RiBriefcase2Line />
                </a>
              </li> */}

              <li className="nav__item">
                <a href="#portfolio" className="nav__link">
                  <RiStackLine />
                </a>
              </li>

              {/* <li className="nav__item">
                <a href="#blog" className="nav__link">
                  <RiDraftLine />
                </a>
              </li> */}

              <li className="nav__item">
                <a href="#contact" className="nav__link">
                  <RiChat3Line />
                </a>
              </li>
            </ul>
          </div>
        </nav>

        <div className="nav__footer">
          {/* <button
            onClick={() => {
              props.switchTheme();
              showMenu(!toggle);
            }}
            className="nav__link footer__button"
          >
            {props.theme === "light" ? <RiMoonLine /> : <RiSunLine />}
          </button> */}
        </div>
      </aside>

      <div
        className={
          toggle ? "nav__toggle nav__toggle-open z-50" : "nav__toggle z-50"
        }
        onClick={() => showMenu(!toggle)}
      >
        <RiMenu2Line />
      </div>
      <button
        onClick={() => {
          props.switchTheme();
          showMenu(false);
        }}
        className="bg-transparent p-2 fixed top-[1.2rem] right-[1.2rem] md:top-[2.5rem] md:right-[2.5rem] z-50 nav__link footer__button"
      >
        {props.theme === "light" ? (
          <RiMoonLine className="w-7 h-7" />
        ) : (
          <RiSunLine className="w-7 h-7" />
        )}
      </button>
    </>
  );
};

export default Sidebar;
