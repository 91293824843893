import React from "react";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";
import "./Home.css";
const HeaderSocials = () => {
  return (
    <div className="home__socials">
      <a
        href="https://www.github.com/yksrocks"
        className="home__social-link"
        target="_blank"
        rel="noreferrer"
      >
        <FaGithub />
      </a>

      <a
        href="https://www.linkedin.com/in/yashksingh-connect"
        className="home__social-link"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedinIn />
      </a>
    </div>
  );
};

export default HeaderSocials;
