import React from "react";
import "./About.css";
import Image from "../../assets/download-MF_js7aYd-transformed-transformed.jpeg";
import Resume from "../../assets/resume.pdf";
import AboutBox from "./AboutBox";

const About = () => {
  const downloadResume = async () => {
    window.open(Resume, "_blank");
  };

  return (
    <section className="about container section" id="about">
      <h2 className="section__title">About Me </h2>

      <div className="about__container grid">
        <img src={Image} alt="" className="about__img rounded-full" />

        <div className="about__data grid">
          <div className="about__info">
            <p className="about__description">
              Hello! I'm Yash, currently based in Ghaziabad, UP. I’m pursuing my
              Bachelor of Technology in Computer Science Engineering (Artificial
              Intelligence) at A.P.J. Abdul Kalam Technical University.
              <br />
              <br />
              Fastforward to today, and I can honestly say it's been a beautiful
              journery and I hope to grow even further as Developer. My current
              focus these days is on expaniding my portfolio by building more
              projects that I can add on here, as well as being a great team
              player at my current role (Technical Team Lead @E-Cell KIET).
              <br />
              <br />
              Here are a few technologies I’ve been working with recently:
            </p>
            <ul className="about__list">
              <li>JavaScript (ES6+)</li>
              <li>TypeScript</li>
              <li>React</li>
              <li>Node.js</li>
              <li>MongoDB</li>
              <li>NextJS</li>
            </ul>
            <button className="btn" onClick={downloadResume}>
              Donwload CV
            </button>
          </div>

          {/* <div className="about__skills grid">
                        <div className="skills__data">
                            <div className="skills__titles">
                                <h3 className="skills__name">Development</h3>
                                <span className="skills__number">90%</span>
                            </div>

                            <div className="skills__bar">
                                <span className="skills__percentage development">

                                </span>
                            </div>
                        </div>

                        <div className="skills__data">
                            <div className="skills__titles">
                                <h3 className="skills__name">UI/UX Design</h3>
                                <span className="skills__number">80%</span>
                            </div>

                            <div className="skills__bar">
                                <span className="skills__percentage ui__design">

                                </span>
                            </div>
                        </div>

                        <div className="skills__data">
                            <div className="skills__titles">
                                <h3 className="skills__name">Photography</h3>
                                <span className="skills__number">60%</span>
                            </div>

                            <div className="skills__bar">
                                <span className="skills__percentage photography">

                                </span>
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>

      <AboutBox />
    </section>
  );
};

export default About;
