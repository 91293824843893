const Menu = [
  {
    id: 1,
    image:
      "https://res.cloudinary.com/dzkyhnxta/image/upload/q_auto/v1/portfolio/wwhza1kw3nqnzcdyjgef",
    title: "E-Cell",
    category: ["Frontend", "ReactJs", "Backend"],
    url: "https://e-cell.in/",
    // repositoryUrl: "https://github.com/GregSithole/react-native-jobs",
  },
  {
    id: 2,
    image:
      "https://res.cloudinary.com/dzkyhnxta/image/upload/q_auto/v1/portfolio/zezyt0t6cejevwq4rips",
    title: "CraftConnect",
    category: ["Frontend", "ReactJs", "Backend"],
    url: "https://craft-connect-app.vercel.app/",
    repositoryUrl: "https://github.com/YksRocks/craftConnect",
  },
  {
    id: 3,
    image:
      "https://res.cloudinary.com/dzkyhnxta/image/upload/q_auto/v1/portfolio/oticauk2s8res1hl6q33",
    title: "Uddeshhya",
    category: ["Frontend", "ReactJs"],
    url: "https://uddeshhya.in/",
    // repositoryUrl: "https://github.com/GregSithole/crypto-app",
  },

  {
    id: 4,
    image:
      "https://res.cloudinary.com/dzkyhnxta/image/upload/q_auto/v1/portfolio/qxnsepzyehckecqcdsg4",
    title: "Mystery Messages",
    category: ["Frontend", "NextJs", "Backend", "TypeScript"],
    url: "https://mystry-message.yashkrsingh.tech/",
    repositoryUrl: "https://github.com/YksRocks/mystryMessages",
  },
  {
    id: 5,
    image:
      "https://res.cloudinary.com/dzkyhnxta/image/upload/q_auto/v1/portfolio/bp0ettkjtx3yinniubsa",
    title: "YelpCamp",
    category: ["Frontend", "EJS", "Backend"],
    url: "https://yelpcamp-fullstack.onrender.com/",
    repositoryUrl: "https://github.com/YksRocks/yelpCamp_fullStack",
  },
  {
    id: 6,
    image:
      "https://res.cloudinary.com/dzkyhnxta/image/upload/q_auto/v1/portfolio/auhm1amb1stitvkeolhu",
    title: "Calculator",
    category: ["Frontend"],
    url: "https://yksrocks.github.io/YksRocks-Calculator/",
    repositoryUrl: "https://github.com/YksRocks/YksRocks-Calculator",
  },
  {
    id: 7,
    image:
      "https://res.cloudinary.com/dzkyhnxta/image/upload/q_auto/v1/portfolio/jmjiablpprlnmcen3vlh",
    title: "Sunnyside Agency Landing Page",
    category: ["Frontend"],
    url: "https://yksrocks.github.io/sunnyside-agency-landing-page-main/",
    repositoryUrl:
      "https://github.com/YksRocks/sunnyside-agency-landing-page-main",
  },
];

export default Menu;
